import React, { useEffect, useState } from "react";
import DefaultHero from "../components/DefaultHero";
import SevenDaysBanner from "../components/SevenDaysBanner";
import Footer from "../components/Footer";
import PriceCalculationButton from "../components/PriceCalculator/PriceCalculationButton";
import Header from "../components/Header";
import OurTeam from "../components/OurTeam";
import { graphql } from "gatsby";
import QueryString from "../helper/QueryString";
import HowTo2 from "../components/HowTo2";
import Testimonials from "../components/Testimonials";
import Video from "../components/Video";
import Alarmanlagen from "../components/Alarmanlagen";
import Tuerschloesser from "../components/Tuerschloesser";
import StringBuilder from "../helper/StringBuilder";
import Slider from "../components/Slider";
import {
    Videoueberwachung,
    Alarmanlage,
    Tuerschloss,
} from "../components/PriceCalculator/elements/Constants";
import OurPartner from "../components/OurPartner";
import FloorPlan from "../components/FloorPlan";
import NewHome3 from "../components/NewHome3";
import NewHome2 from "../components/NewHome2";
import Reviews from "../components/Reviews";
import Products_page_new_Comp from "../components/Products_page_new_Comp";
import Products_page_new_Comp1 from "../components/Products_page_new_Comp1";
import Products_page_new_CompSlider from "../components/Products_page_new_CompSlider";
// import "../styles/index.scss";
// export const HeroElement = ({ city, stringBuilder, product, values }) => {
//     let slug = city ? city.slug : null;
//     return (
//         <div className="product homepage">
//             <h1 className="top">
//                 {stringBuilder.getHeroProductName()} von der Nr. 1{" "}
//                 {city ? stringBuilder.getInStadt() : " in Deutschland"}
//             </h1>
//             <h2 className="middle">{values}</h2>
//             <PriceCalculationButton city={slug} product={product} />
//         </div>
//     );
// };

export const HeroElement = () => {
    // const [state, setState] = useState();
    // useEffect(() => {
    //     setTimeout(() => {
    //         console.log("usestate fired in product page hero element");
    //         setState(true);
    //     }, 3000);
    // }, []);
    let windowWidth = 541;
    if (typeof window != `undefined`) {
        windowWidth = window.innerWidth;
    }

    let path = "";
    let name = "Alarmanlagen";
    if (typeof window !== "undefined" && typeof document !== "undefined") {
        path = window.location.pathname;
        path = path.replace(/\//g, "");
    }
    if (path.includes("schliessanlagen")) {
        name = "Schließanlagen";
    }
    if (path.includes("videoueberwachung")) {
        name = "Videoanlagen";
    }
    console.log("path is", path);
    const colourChangeArray = [
        "schliessanlagen",
        "kielschliessanlagen",
        // "alarmanlagen",
    ];
    let textColor = "white";
    let fontSize;
    if (colourChangeArray.includes(path)) {
        textColor = "#193351";
    }
    if (windowWidth <= 500) {
        fontSize = "18px";
    }
    if (windowWidth <= 768) {
        textColor = "#193351";
    }
    console.log("windowWidth from product-page: ", windowWidth);
    return (
        <div className="homepage display-max-500">
            <h1
                className="top "
                style={{ color: textColor, fontSize: fontSize ?? fontSize }}
            >
                Ihr kostenloser {name}-Preisrechner
            </h1>
            <span
                className="middle "
                style={{ color: textColor, marginTop: "-20px" }}
            >
                In nur wenigen Minuten Preis berechnen
                {/* In wenigen Minuten zur Preisindikation */}
            </span>
            {/* <PriceCalculationButton id="btn_bighero_preisberechnen" /> */}
        </div>
    );
};
export default ({ data, pageContext }) => {
    const { city, product, values } = pageContext;
    let stringBuilder = new StringBuilder(city, product);
    // const slug = data.markdownRemark.frontmatter.slug;

    const post = data.markdownRemark;
    if (typeof city !== "undefined") {
        let query = new QueryString();
        query.setCity(city);
    }
    const slug = data.markdownRemark.frontmatter.slug;
    const slug2 = pageContext.slug;
    console.log("slug2 isssssssssssssss", slug2);
    if (typeof city !== "undefined") {
        pageContext = {
            title: city.title.replace(
                "Sicherheitstechnik",
                stringBuilder.getMetaDataProductName()
            ),
            ogTitle: city.ogTitle.replace(
                "Sicherheitstechnik",
                stringBuilder.getMetaDataProductName()
            ),
            description: city.description.replace(
                "Sicherheitstechnik",
                stringBuilder.getMetaDataProductName()
            ),
            ogDescription: city.ogDescription.replace(
                "Sicherheitstechnik",
                stringBuilder.getMetaDataProductName()
            ),
            keywords: stringBuilder.getMetaDataProductName() + " " + city.name,
        };
    }
    console.log("pageContext is", JSON.stringify(pageContext, null, 2));
    console.log("data is", JSON.stringify(data, null, 2));
    let contextData = {
        title: typeof city !== "undefined" ? city.title : pageContext.title,
        image: "/img/hero/default/" + post.frontmatter.image,
        image768: "/img/hero/default/" + post.frontmatter.image768,
        image1024: "/img/hero/default/" + post.frontmatter.image1024,
        image1216: "/img/hero/default/" + post.frontmatter.image1024,
        image1408: "/img/hero/default/" + post.frontmatter.image1024,
        // showPriceCalculator: false,
        // showHeroMobile: false,
        // showBottom: true,
        product: product,
        // showElement: (
        //     <HeroElement
        //         city={city}
        //         product={product}
        //         values={values}
        //         stringBuilder={stringBuilder}
        //     />
        // ),
        showPriceCalculator: true,
        showHeroMobile: false,
        showBottom: true,
        showElement: <HeroElement />,
        city: city,
        noIndex: false,
        backgroundColor: "whiteTextHero",
    };
    const SeoText = () => {
        let currentUrl = "";
        if (typeof window !== `undefined`) {
            currentUrl = window.location.pathname;
        }
        if (currentUrl) {
            return (
                <div>
                    {currentUrl == "alarmanlagen" ? (
                        <div className="mainSeoText">
                            <h2>
                                In nur wenigen Stunden werden Sie sich sicher
                                fühlen
                            </h2>
                            <h3>So funktionieren drahtlose Alarmsysteme</h3>
                            Drahtlose Alarmanlagen sind drahtlose
                            Sicherheitssysteme, die sowohl im Innen- als auch im
                            Außenbereich eingesetzt werden können. Sie enthalten
                            Sensoren wie Rauch-, Bewegungs- und Öffnungsmelder.
                            Sie übermitteln die Informationen per Funk an eine
                            Alarmzentrale, die Eindringlinge erkennt. Die
                            Alarmzentrale steuert dann eine Außensirene, ein
                            Telefonwählgerät über Funk und alarmiert die nahe
                            gelegene Notruf- und Leitstelle. Sie können auch
                            Sirenen in Ihrem Gebäude oder einen Außenvoralarm
                            auslösen.
                            <h3>Es müssen keine Kabel verlegt werden</h3>
                            Funkalarmanlagen können ohne Verkabelung installiert
                            werden. Durch die Funktechnologie können alle Geräte
                            miteinander kommunizieren. Sie können mit Batterien
                            aufgeladen werden, was jahrelang hält. Es ist
                            offensichtlich, dass keine Kabel verlegt werden
                            müssen. Das bedeutet, dass keine Löcher gebohrt und
                            neu verputzt werden müssen. Die Wände, Fenster und
                            Türen bleiben unangetastet.{" "}
                            <h3>Saubere Installation</h3> Die Installation von
                            Funkalarmmodulen dauert nur wenige Stunden. Es
                            müssen keine Kabel verlegt werden und es gibt keinen
                            Baustress. Sie fühlen sich sicher und Ihre Räume
                            bleiben sauber. Sie sind sofort und zuverlässig
                            geschützt. <h3>Flexibel und ausbaufähig</h3>{" "}
                            Funkalarmanlagen sind modular aufgebaut und damit
                            flexibel und ausbaufähig. Ideal für Neubauten und
                            Modernisierungen. Bei einem Umzug können Sie Ihre
                            Alarmanlage einfach mitnehmen.
                            <h3>Bequeme Bedienung</h3> Die Funkalarmanlage ist
                            einfach zu bedienen und verfügt über eine klare
                            Sprachausgabe. Sie können die Funkalarmanlage über
                            einen Handsender, Transponder oder eine Tastatur
                            bedienen, oder Sie steuern Ihr Zuhause über eine
                            App.
                            <h3> Die Lebensdauer der Batterie ist lang</h3> Die
                            Lebenserwartung der Hochleistungsbatterien beträgt
                            bei normalem Gebrauch je nach Hersteller 2-5 Jahre.
                            Die Bedienteile und Smartphone Apps benachrichtigen
                            den Nutzer, wenn die Leistung des Akkus nachlässt
                            und ein Batteriewechsel notwendig wird.
                        </div>
                    ) : (
                        ""
                    )}
                    {currentUrl == "schliessanlagen" ? (
                        <div className="mainSeoText">
                            <h2>Elektronische und digitale Schließsysteme</h2>
                            Wenn von elektronischen Schließanlagen die Rede ist,
                            werden häufig digitale Schließsysteme gemeint. Beide
                            Systeme nutzen die gleiche Technologie, egal ob es
                            sich um ein elektrisches Schließsystem an der
                            Haustür oder um ein digital gesteuertes
                            Schließsystem auf einem Universitätsgelände handelt.
                            Alle elektronischen und digitalen Systeme
                            funktionieren nach dem gleichen Prinzip:
                            Schließmechanismen in Kombination mit
                            softwaregesteuerter Technik. Die technischen
                            Möglichkeiten sind nahezu grenzenlos, so dass sich
                            hochkomplexe Schließhierarchien oder
                            Berechtigungsstrukturen abbilden lassen. Digitale
                            Schließsysteme bieten sowohl Komfort als auch
                            Sicherheit. Digitale Schließsysteme sind eine gute
                            Option für Privathäuser, Unternehmen und
                            Wohngebäude. Für Unternehmen sind diese Systeme
                            besonders nützlich, da sie die Verwaltung und
                            Ausgabe von Schlüsseln erleichtern. Ein digitales
                            Schließsystem hat den zusätzlichen Vorteil, dass Sie
                            jederzeit verfolgen können, wer sich innerhalb und
                            außerhalb des Gebäudes aufhält. Mit einer Software
                            lässt sich der Zugang zu diesen Bereichen leicht
                            kontrollieren. So können Sie einfach und
                            benutzerfreundlich im Schließplan hinterlegen, wer
                            zu welchen Bereichen Zugang hat. So können Sie
                            leicht zwischen Mitarbeitern, Lieferanten und Gästen
                            unterscheiden. Größere Organisationen und
                            öffentliche Einrichtungen müssen über
                            Zutrittsberechtigungen verfügen, die eine gewisse
                            Flexibilität zulassen, da sowohl externes Personal
                            (z.B. Lieferanten und Reinigungspersonal) als auch
                            internes Personal Zutritt haben muss. Schlüssellose
                            Systeme sind auch für Schulen, Krankenhäuser und
                            Universitäten von Vorteil, die ihre Schlüssel häufig
                            wechseln müssen. Dies wird durch ein elektronisches
                            Schließsystem ermöglicht. Das elektronische
                            Schließsystem bietet zudem blitzschnelle
                            Reaktionszeiten bei Verlust eines
                            Identifikationsmediums. Kleinere digitale
                            Schließanlagen können zur Sicherung von privaten
                            Wohngebäuden eingesetzt werden. Das System kann
                            erweitert und aufgerüstet werden, um ganze
                            Wohngebäude oder Mehrfamilienhäuser zu versorgen.
                            Elektronische Schließanlagen können zur Verwaltung
                            von Anlagen in Wohnungsgenossenschaften eingesetzt
                            werden und ermöglichen deren Online-Überwachung.
                            Auch Hotels haben den Wert digitaler Systeme
                            erkannt. Mit SmartCards lassen sich Hotelzimmer
                            heute berührungslos öffnen. Das jeweilige
                            Identifikationsmedium kann vom Personal einfach
                            ausgegeben, gesperrt oder ersetzt werden, ohne dass
                            Spezialisten hinzugezogen werden müssen. Egal, ob es
                            sich um ein Unternehmen, ein öffentliches Gebäude
                            oder eine Privatwohnung handelt, alle können von der
                            Möglichkeit profitieren, das Smartphone oder einen
                            Transponder als Code zu nutzen. Die App sendet die
                            Zugangsdaten vom Smartphone an das Lesegerät. Ist
                            die Berechtigung gegeben, wird der Zutritt gewährt.
                        </div>
                    ) : (
                        ""
                    )}
                    {currentUrl == "videoueberwachung" ? (
                        <div className="mainSeoText">
                            <h2>Was bedeutet eigentlich Videoüberwachung?</h2>
                            <p>
                                Darunter versteht man die Verarbeitung
                                personenbezogener Daten mit
                                optisch-elektronischen Geräten. Dazu gehört die
                                Videoüberwachung, d.h. die Live-Übertragung und
                                Wiedergabe von Bildern auf einem Monitor sowie
                                die Videoaufzeichnung. Optisch-elektronische
                                Systeme umfassen nicht nur Kameras, die für die
                                Überwachung ausgelegt sind. Sie können auch
                                andere Geräte verwenden, z. B. ein Smartphone
                                oder eine Webcam, eine Dashcam, eine Kamera an
                                einer Türklingel und eine Gamecam. Die
                                Datenverarbeitung ist zulässig, solange sie den
                                Anforderungen der allgemeinen
                                Datenschutzverordnung entspricht. Die einzige
                                Ausnahme ist die Datenerhebung für
                                familienbezogene Zwecke. Hierbei handelt es sich
                                nicht um Videoüberwachung, wenn keine
                                personenbezogenen Daten verarbeitet werden.
                            </p>
                            <h3>
                                Wann ist eine Videoüberwachung in Unternehmen
                                sinnvoll?
                            </h3>
                            Seit vielen Jahren ist die Videoüberwachung ein Teil
                            unseres täglichen Lebens. Sie wird immer weiter
                            ausgebaut. Man findet Videoüberwachung in
                            Geschäften, Banken, Unternehmen, öffentlichen
                            Gebäuden und auf Plätzen. Schützen Sie Ihr
                            Unternehmen mit Videoüberwachung vor Diebstahl und
                            Vandalismus. Kriminelle sind immer auf der Suche
                            nach Zielen in Unternehmen. Einige Kriminelle
                            brechen in Unternehmen ein, weil sie glauben, dass
                            sich Bargeld oder wertvolle Werkzeuge darin
                            befinden. Wenn sie die gewünschten Gegenstände nicht
                            bekommen, geben viele ihrer blinden Zerstörungswut
                            nach. Manchmal kann Vandalismus mehr Schaden
                            anrichten als Diebstahl.
                            <h3>
                                Wo liegen die rechtlichen Grenzen der
                                Videoüberwachung von Privatgrundstücken?
                            </h3>
                            Die Überwachung von Privatgrundstücken ist durch den
                            Einsatz von Videokameras grundsätzlich erlaubt.
                            Privatgrundstücke können mit Videokameras überwacht
                            werden, sofern Sie die Datenschutzbestimmungen
                            einhalten. Das versehentliche Filmen des
                            Nachbargrundstücks ist nicht erlaubt. Auch das
                            Filmen von Passanten auf dem Gehweg neben Ihrem
                            Grundstück oder im Garten Ihres Nachbarn ist
                            rechtswidrig. Die Kamera darf keine Aufnahmen von
                            einem öffentlichen Platz oder einem öffentlichen Weg
                            machen. Das Recht auf Privatsphäre ist ein wichtiger
                            Faktor bei der Videoüberwachung von
                            Privatgrundstücken. Aufgrund des
                            Persönlichkeitsrechts kann jede Person selbst
                            entscheiden, ob und wann sie gefilmt werden möchte.
                            Dabei kann es sich um ein Privatgrundstück oder um
                            ein Firmengelände handeln. Um sicherzustellen, dass
                            Sie nicht Gefahr laufen, illegal zu filmen, müssen
                            Sie der gesetzlichen Verpflichtung nachkommen, über
                            die Überwachung zu informieren. Sie müssen ein
                            Schild anbringen, das deutlich darauf hinweist, dass
                            Ihr Eigentum überwacht wird. Wenn Personen dann das
                            Grundstück betreten, erklären sie sich damit
                            einverstanden, dass sie gefilmt werden. Dadurch wird
                            Ihre Sicherheit gewährleistet. Unsere
                            professionellen Sicherheitsberater sind bestens mit
                            den rechtlichen Bestimmungen vertraut und stellen
                            sicher, dass Ihre Videoanlage alle gesetzlichen
                            Verpflichtungen erfüllt.
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            );
        } else {
            return <div></div>;
        }
    };
    let currentUrl = "";
    if (typeof window !== `undefined`) {
        currentUrl = window.location.pathname;
    }
    return (
        <div>
            <Header
                page={pageContext}
                siteMetadata={data.site.siteMetadata}
                contextData={contextData}
            />
            <DefaultHero context={contextData} />

            {slug == "alarmanlagen" ? <NewHome3 /> : ""}
            {/* {currentUrl.includes("/alarmanlagen") ? <NewHome3 /> : ""} */}

            <HowTo2
                city={city}
                product={product}
                showPriceCard={slug == "alarmanlagen" ? true : false}
            />
            {/* {slug == "alarmanlagen" ? <Reviews /> : ""} */}
            {slug == "alarmanlagen" ? <Products_page_new_Comp /> : ""}
            {/* {currentUrl.includes("/alarmanlagen") ? (
                <Products_page_new_Comp />
            ) : (
                ""
            )} */}

            {/* <OurPartner /> */}
            {slug == "alarmanlagen" ? (
                <Slider
                    product={product}
                    header="Ihr neues Alarmsystem - modular aufgebaut"
                    subheader="So könnte Ihre Alarmanlage für Haus, Wohnung oder Gewerbe aussehen"
                />
            ) : (
                ""
            )}
            {slug == "videoueberwachung" ? (
                <Slider
                    product={product}
                    header="Ihre neue Videoüberwachungsanlage"
                    subheader="So könnte Ihre Videoüberwachungsanlage für Haus, Wohnung oder Gewerbe aussehen"
                />
            ) : (
                ""
            )}
            {slug == "schliessanlagen" ? (
                <Slider
                    product={product}
                    header="Ihre neue elektronische Schließanlage"
                    subheader="So könnte Ihre Schließanlage für Haus, Wohnung oder Gewerbe aussehen"
                />
            ) : (
                ""
            )}
            {/* <FloorPlan city={city} /> */}
            {slug == "alarmanlagen" ? <Products_page_new_Comp1 /> : ""}
            {/* {currentUrl.includes("/alarmanlagen") ? (
                <Products_page_new_Comp1 />
            ) : (
                ""
            )} */}
            {slug == "alarmanlagen" ? <Products_page_new_CompSlider /> : ""}
            {/* {currentUrl.includes("/alarmanlagen") ? (
                <Products_page_new_CompSlider />
            ) : (
                ""
            )} */}
            {slug == "alarmanlagen" ? <NewHome2 /> : ""}
            {/* {currentUrl.includes("/alarmanlagen") ? <NewHome2 /> : ""} */}
            {product === Tuerschloss && (
                <Tuerschloesser city={city} business={true} />
            )}
            {product === Videoueberwachung && <Video city={city} />}
            {/* {product === Alarmanlage && <Alarmanlagen city={city} />} */}
            {/* <Testimonials city={city} product={product} />
                <OurTeam city={city} /> */}
            {city && product === Alarmanlage && city.content && (
                <section className="city-page">
                    <div className="container layout">
                        <div
                            className="content"
                            dangerouslySetInnerHTML={{
                                __html: city.content,
                            }}
                        ></div>
                    </div>
                </section>
            )}

            <SeoText />

            <SevenDaysBanner cssclassName="with-background" city={city} />
            <Footer productSlug={post.frontmatter.slug} product={product} />
        </div>
    );
};

export const query = graphql`
    query ProductQuery($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                slug
                title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
                image
                image768
                image1024
                image1216
                image1408
                hero
            }
        }
        site {
            siteMetadata {
                title
                keywords
                description
                headerImage
                ogTitle
                ogDescription
            }
        }
    }
`;
